import React, { useState } from "react";
import "./pagetest.css";

export default function Modal() {
 
  return (

    <div className="pagetest" >
      <p>Test Room</p>
      <p>Add your demo content or component for test  </p>
    </div>
    
  );
}